
$('.omni-row__response-btn').each(function () {
    var $this = $(this);
    $this.on("click", function () {
        $("#id_referral").val($(this).data('omni-referral-id'));
        $("h5.offcanvas-title").html( $(this).data('omni-referral-loc') + ' <address>' + $(this).data('omni-referral-address') + '</address>');
        
        console.log($(this).data('omni-referral-soc-d'));
        if($(this).data('omni-referral-soc-d') === 1) {
            console.log('yes');
            $("#soc_field").removeClass('d-none');
            
            if($(this).data('omni-referral-soc-r') === 1) {
                $("#soc_field").find('input').prop('required', true);
            }
        }
    });
});

$('#request-form').submit(function (e) {
    e.preventDefault();

    var $form = $(this),
        $formSubmit = $('#request-button'),
        formArray = $form.serializeArray(),
        data = $('form#request-form').serialize();

    $formSubmit.attr('disabled', 'disabled').text('Loading...');

    /*for (var i = 0; i < formArray.length; i++) {
        data[formArray[i].name] = formArray[i].value;
    }*/
    
    
    $.ajax({
        type: "POST",
        url: "process.php",
        data: data,
        success: function(msg){
            if(msg === 'error') {
                //alert('Error: ' + msg);
                $formSubmit
                    .parent()
                    .html('<strong>We run into an error lets refresh the page!</strong>');

                setTimeout(function(){
                    location.reload();
                }, 2000); // 2 seconds
            } else if(msg === 'success') {
                location.reload();
            }
            console.log(msg);
        },
        error: function(){
            
            $formSubmit
                .parent()
                .html('<strong>We run into an error lets refresh the page!</strong>');

            setTimeout(function(){
                location.reload();
            }, 2000); // 2 seconds
            console.log('ajax error');
        }
    });
    
    

    /*$.ajax({
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        url: "process.php", //$form.attr('action'),
        data: $('form#request-form').serialize(),
        //data: JSON.stringify(data),
    }).always(function (data) {
        console.log(data);
        if (data.status === 422) {
            alert('Ajax requests do not support captcha. Please turn it off.');
            $formSubmit
                .parent()
                .html('<strong>Error, failed to send the message!</strong>');
        }

        if (data.status !== 200) {
            alert('Error: ' + data.statusText + ' (' + data.status + ')');
            $formSubmit
                .parent()
                .html('<strong>Error, failed to send the message!</strong>');
        }

        $formSubmit.parent().html('Thank you for contacting us!');
    });*/
});





/*$(function() {
    //twitter bootstrap script
    $("button#request-button").click(function(){
        $.ajax({
            type: "POST",
            url: "include/process.php",
            data: $('form#request-form').serialize(),
            success: function(msg){
                console.log(msg);
                //$("#thanks").html(msg)
                //$("#form-content").modal('hide');
            },
            error: function(){
                alert("failure");
            }
        });
    });
});*/


